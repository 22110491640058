import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import ProcessTable from '../components/RobotsPageComponents/ProcessTable';
import MachinesTable from '../components/RobotsPageComponents/MachinesTable';
import NewMachineDialog from '../components/RobotsPageComponents/NewMachineDialog';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";

export default function Robots({ initialTab }) {


const [isDialogOpen, setIsDialogOpen] = useState();
const [tabValue, setTabValue] = useState(initialTab);

const handleClickOpen = () => {
    setIsDialogOpen(true)
};

const handleClose = () => {
    setIsDialogOpen(false)
};

const handleTabChange = (event, newValue) => {
    setTabValue(newValue)
};




        return (
            <div>
                <Box sx={{ width: '100%' }}>
                    
                    <Tabs
                        value={tabValue}
                        onChange={handleTabChange}
                        textColor="secondary"
                        indicatorColor="secondary"
                        aria-label="robots tabs"
                    >
                        
                        <Tab tag={Link} to="/robots/processes"  value="Processes" label="Processes"/>
                        <Tab value="Machines" label="Machines" />
                    </Tabs>
                </Box>

                
                {
                    tabValue == "Processes" &&
                    <>
                        

                        <ProcessTable></ProcessTable>
                    </>

                }

                {
                    tabValue == "Machines" &&
                    <>
                        
                        <NewMachineDialog dialogOpen={isDialogOpen}></NewMachineDialog>
                        <MachinesTable></MachinesTable>
                    </>
                }


            </div>
        );
    }

