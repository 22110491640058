import * as React from 'react';
import TextField from '@mui/material/TextField';

export default function AddQueue({ handleClose}) {
    const [queueName, setQueueName] = React.useState('');
    const [queueDescription, setQueueDescription] = React.useState('');

    const handleSubmit = (event) => {
        console.log(queueName);
        

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ name: queueName, description: queueDescription})
        };
        fetch('api/createqueue', requestOptions)
            .then(() => {

                handleClose();
            });
    };
    const handleNameChange = event => {
        setQueueName(event.target.value);
    };

    const handleDescriptionChange = event => {
        setQueueDescription(event.target.value);
    };


    


    return (
        <>
           
                
                
                <div className="form-group mb-3">
                <div className="form-group mb-3">
                    <TextField id="queue-name" label="Queue Name" variant="outlined" onChange={handleNameChange} />
                </div>
                <div className="form-group mb-3">
                <TextField id="queue-description" label="Queue Description" variant="outlined" onChange={handleDescriptionChange} multiline
                        rows={4} />
                    </div>
                </div>
                <button className="btn btn-primary" type="submit" onClick={handleSubmit}>Add</button>
           
        </>
    );
}
