import * as React from 'react';
import Button from '@mui/material/Button';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import ProcessVersionsDialog from './ProcessVersionsDialog';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useState } from 'react';


export default function ProcessActionsMenu(props) {

    const [versionsDialogOpen, setVersionsDialogOpen] = useState(false)
    const [detailsDialogOpen, setDetailsDialogOpen] = useState(false)
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
        
    };

    const handleVersionsDialogOpen = () => {
        setVersionsDialogOpen(true)
        handleClose()
    }
    const handleDetailsDialogOpen = () => {
        setDetailsDialogOpen(true)
        handleClose()
    }
    const handleDialogClose = () => {
        setVersionsDialogOpen(false)
        setDetailsDialogOpen(false)
    }

    const handleDeleteProcess = () => {
        fetch('api/deleteprocess', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ guid: props.row.guid })
        }).then(() => { handleClose() })
        props.setForceRenderCounter(props.forceRenderCounter + 1)
    }


    return (
        <>
            <Button
                id="robot-button"
                aria-controls={open ? 'robot-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
                color='neutral'
            >
                <MoreVertIcon />
            </Button>

            <Menu
                id="robot-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'job-button',
                }}
            >
                <MenuItem dense={true} ><Button onClick={handleVersionsDialogOpen} color="info">View Versions</Button></MenuItem>
                <MenuItem dense={true} ><Button onClick={handleDetailsDialogOpen} color="info">View Details</Button></MenuItem>
                <MenuItem dense={true} ><Button onClick={handleDeleteProcess} color="error">Delete Process</Button></MenuItem>

            </Menu>

           

            <Dialog open={versionsDialogOpen} onClose={handleDialogClose}>
                <DialogTitle>Versions of {props.row.name}</DialogTitle>
                <DialogContent>
                    <ProcessVersionsDialog row={props.row} maxversion={props.maxversion}></ProcessVersionsDialog>
                </DialogContent>
               
            </Dialog>

            <Dialog fullWidth={false} maxWidth='xl' open={detailsDialogOpen} onClose={handleDialogClose}>
                <DialogTitle>Details of {props.row.name}</DialogTitle>
                <DialogContent>
                    <div dangerouslySetInnerHTML={{ __html: props.row.data }}>
                    </div>
                </DialogContent>

            </Dialog>
            </>
    );
}
