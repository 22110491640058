import * as React from 'react';
import PopoverComponent from '../Common/PopperComponent';
import Button from '@mui/material/Button';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ButtonGroup from '@mui/material/ButtonGroup';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import ProcessVersionsDialog from './ProcessVersionsDialog';
import { useState, useEffect, useRef } from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import Box from '@mui/material/Box';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import MachineWizardStepper from './MachineWizardStepper';
import Container from '@mui/material/Container';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function NewMachineDialog(props) {

    const [dialogOpen, setDialogOpen] = useState(false)

    
    

    
   
    const handleDialogClose = () => {
        setDialogOpen(false)
        
    }

    const handleDialogOpen = () => {
        setDialogOpen(true)

    }


    return (
      <> <Box sx={{ '& > :not(style)': { m: 1 } }}>
            <Fab onClick={handleDialogOpen} title="Add Machine" className="floatRight" color="primary" aria-label="add">
                                <AddIcon />
                            </Fab>

                        </Box>
        <Dialog
        fullScreen
            open={dialogOpen}
        onClose={handleDialogClose}
        TransitionComponent={Transition}
      >
        <AppBar color="info" sx={{ position: 'relative' }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
                        onClick={handleDialogClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Connect New Machine
            </Typography>
                   
          </Toolbar>
                </AppBar>
                <DialogContent>
                    <Container maxWidth="lg">
                        <MachineWizardStepper handleDialogClose={handleDialogClose}></MachineWizardStepper>
                    </Container>
                </DialogContent>
      </Dialog>
            </>
            
    );
}
