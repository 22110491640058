import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import AddQueue from '../components/DataPageComponents/AddQueue'

import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";
import GenericDataTable from '../components/Common/GenericDataTable';

export default function Data({ initialTab }) {


    const [tabValue, setTabValue] = useState(initialTab);
    const [isDialogOpen, setIsDialogOpen] = useState();

    const handleClickOpen = () => {
        setIsDialogOpen(true)
    };

    const handleClose = () => {
        setIsDialogOpen(false)
    };

const handleTabChange = (event, newValue) => {
    setTabValue(newValue)
    };

    useEffect(() => {
        console.log("dialog")

    }, [isDialogOpen])




        return (
            <div>
                <Box sx={{ width: '100%' }}>
                    
                    <Tabs
                        value={tabValue}
                        onChange={handleTabChange}
                        textColor="secondary"
                        indicatorColor="secondary"
                        aria-label="robots tabs"
                    >
                        
                        <Tab tag={Link} to="/data/queues" value="Queues" label="Queues" />
                        <Tab tag={Link} to="/data/storage" value="Storage" label="Storage" />
                    </Tabs>
                </Box>

                
                {
                    tabValue == "Queues" &&
                    <>
                        <Dialog open={isDialogOpen} onClose={handleClose}>
                            <DialogTitle>Add Queue</DialogTitle>
                            <DialogContent>
                                <AddQueue handleClose={handleClose}></AddQueue>
                            </DialogContent>

                        </Dialog>

                        <Box sx={{ '& > :not(style)': { m: 1 } }}>
                            <Fab title="Add Job" className="floatRight" color="primary" aria-label="add" onClick={handleClickOpen}>
                                <AddIcon />
                            </Fab>

                        </Box>
                        <GenericDataTable pageUrl="/data/queues" fetchUrl="api/getqueues" ovverideColumns={["name", "description"]} refreshTrigger={isDialogOpen}  > </GenericDataTable>
                        
                    </>

                }

                {
                    tabValue == "Storage" &&
                    <>
                        
                        
                    </>
                }


            </div>
        );
    }

