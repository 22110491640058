import * as React from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Button from '@mui/material/Button';
import { useState, useEffect } from "react";
import Chip from '@mui/material/Chip';

export default function ProcessVersionsDialog(props) {
    const [value, setValue] = React.useState(props.row.version);
    const [versionRows, setVersionRows] = React.useState([]);

    const handleChange = (event) => {
        setValue(event.target.value);
    };

    const setActiveRobotVersion =  () => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ version: value, guid: props.row.guid })
        };
        fetch('api/setactiverobotversion', requestOptions);
    }

    useEffect(() => {

        let query = "api/getrobots?guid=" + props.row.guid
        fetch(query)
            .then(res => res.json())
            .then(res => { setVersionRows(res.results); console.log(res.results); })
            .catch(err => console.log(err));
    }, []);

    return (
        <form onSubmit={setActiveRobotVersion}>
        <FormControl>
            <FormLabel id="version-group">Choose active version</FormLabel>
            <RadioGroup
                aria-labelledby="version-group"
                name="version-group"

                value={value}
                onChange={handleChange}
            >
                {versionRows.map((row, index) => {
                    let versionColor = "info"
                    let description = ""
                    if (row.is_active === '1') { versionColor = "success"; description = " (active)" }
                    if (row.is_active !== '1' && row.version === props.maxversion) {  description = " (latest)" }
                    return (
                        <div color={versionColor} variant="contained" aria-label="outlined primary button group">
                            <FormControlLabel value={row.version} control={<Radio color={versionColor} />} /><Chip label={row.version} color={versionColor} variant="contained" />{description}
                        </div>
                    );
                }
                    )
                    }
               
                
                </RadioGroup>
                <Button type="submit" variant="contained">Save</Button>

            </FormControl>

            </form>
    );
}
