import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import { useState, useEffect, useRef } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

const steps = ['Generate Machine Key', 'Add Key to Robot Force', 'Connect with Command Center'];

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

export default function MachineWizardStepper(props) {
    const [activeStep, setActiveStep] = React.useState(0);
    const [newMachineGuid, setNewMachineGuid] = useState(null);
    const [registerCompleted, setRegisterCompleted] = useState(false)

    useEffect(() => {
        const interval = setInterval(() => {
            isUserConnected(); console.log('interval');
            
        }, 2000);
        return () => clearInterval(interval);
    }, [activeStep]);
        

    const generateNewMachineKey = () => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },

        };
        fetch('api/createmachine', requestOptions)
            .then(res => res.text())
            .then(res => { console.log(res); setNewMachineGuid(res); })
            .catch(err => console.log(err));
    }

    function isUserConnected() {
        
        fetch("api/getmachineusers?machinekey=" + newMachineGuid)
                .then(res => res.json())
                .then(res => {
                    console.log(res.results);
                    if (res.results.length > 0) {
                        setRegisterCompleted(true)
                    }
                })
                .catch(err => console.log(err));
        
        
       
    }

    const handleNext = () => {
        

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        if(activeStep === 0)
            generateNewMachineKey();
        
        
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    

    const handleReset = () => {
        setActiveStep(0);
    };

    const stepText0 = "Click Next to generate a new machine key"
    const stepText1 = <><p>Input the key into <b>machineKey</b> field in Robot Force application settings</p></>
    const stepText2 = <><p>Input <b>{window.location.origin.toString()}</b> into <b>commandCenterUrl</b> field</p><p>Click Save and wait for connection</p></>
    const stepCompleted = "Machine succesfully connected"

    return (
       <> <Box sx={{ width: '100%', zoom: '150%' }}>
            <Stepper size="large" activeStep={activeStep}>
                {steps.map((label, index) => {
                    
                    
                    return (
                        <Step  key={label} >
                            <StepLabel>{label}</StepLabel>
                        </Step>
                    );
                })}
            </Stepper>
            
        </Box>
            <Box sx={{ flexGrow: 1, marginTop: '20vh', textAlign: 'center' }}>
                <Typography sx={{ textAlign: 'center' }} variant="h5" >
                    {activeStep == 0 && stepText0}
                    {activeStep == 1 && stepText1}
                    {(activeStep === 2 && registerCompleted === false) && stepText2}
                    {(activeStep === 2 && registerCompleted === true) && stepCompleted}
                </Typography>
                <Grid container spacing={0}>
                    <Grid item xs>
                    </Grid>
                    <Grid sx={{ textAlign: 'center', padding: '5%' }} item xs={6}>
                        <Card sx={{ minWidth: 275 }}>
                            <CardContent>
                                {activeStep === 0 && <VpnKeyIcon color="info" sx={{ fontSize: 100 }}></VpnKeyIcon>}
                                {activeStep === 1 && <p>{newMachineGuid}</p>}
                                {(activeStep === 2 && registerCompleted === false) && <CircularProgress color="info" sx={{ fontSize: 100 }}></CircularProgress>}
                                {(activeStep === 2 && registerCompleted === true) && <CheckCircleOutlineIcon color="success" sx={{ fontSize: 100 }}></CheckCircleOutlineIcon>}

                            </CardContent>
                        </Card>

                    </Grid>
                    <Grid item xs>
                    </Grid>
                </Grid>
                {activeStep < 2 && 
                    <Button onClick={handleNext} size="large" variant="contained">Next</Button>
                }
                {registerCompleted &&
                    <Button onClick={props.handleDialogClose} size="large" variant="contained">Done</Button>
                }
            </Box>
        
            </>
    );
}
