import * as React from 'react';
import Button from '@mui/material/Button';
import { useState, useEffect } from "react";



export default function KillJobButton(props) {

    const [jobGuid, setJobGuid] = useState("");

    useEffect(() => {
        console.log(props.guid)
        setJobGuid(props.guid)
    }, []);

    function handleOnClick() {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ guid: jobGuid })
        };
        fetch('api/killjob', requestOptions);
    }

    return (
       
        <Button color="error" onClick={handleOnClick}>
                        Kill
                    </Button>
                    
           
    );
}
