import * as React from 'react';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterListIcon from '@mui/icons-material/FilterList';
import { visuallyHidden } from '@mui/utils';
import { useState, useEffect, useRef } from "react";
import PopoverComponent from '../Common/PopperComponent';
import ColorChips from '../Common/ColorChips';
import Button from '@mui/material/Button';
import KillJobButton from './KillJobButton';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import JobActionsMenu from './JobActionsMenu';
import { useNavigate } from 'react-router-dom';






function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const headCells = [
    {
        id: 'name',
        numeric: false,
        disablePadding: false,
        label: 'Robot Name',
    },
    {
        id: 'status',
        numeric: false,
        disablePadding: false,
        label: 'Status',
    },
    {
        id: 'guid',
        numeric: false,
        disablePadding: false,
        label: 'ID',
    },
    {
        id: 'machine',
        numeric: false,
        disablePadding: false,
        label: 'Machine',
    },
    {
        id: 'start_date',
        numeric: false,
        disablePadding: false,
        label: 'Start Date',
    },
    {
        id: 'end_date',
        numeric: false,
        disablePadding: false,
        label: 'End Date',
    },
    {
        id: 'duration',
        numeric: false,
        disablePadding: false,
        label: 'Duration',
    },
    {
        id: 'exception',
        numeric: false,
        disablePadding: false,
        label: 'Exception',
    },

];

function EnhancedTableHead(props) {
    const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
        props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };


    return (

        <TableRow>
            <TableCell padding="checkbox">
                <Checkbox
                    color="primary"
                    indeterminate={numSelected > 0 && numSelected < rowCount}
                    checked={rowCount > 0 && numSelected === rowCount}
                    onChange={onSelectAllClick}
                    inputProps={{
                        'aria-label': 'select all desserts',
                    }}
                />
            </TableCell>
            {headCells.map((headCell) => (
                <TableCell
                    key={headCell.id}
                    align={headCell.numeric ? 'right' : 'left'}
                    padding={headCell.disablePadding ? 'none' : 'normal'}
                    sortDirection={orderBy === headCell.id ? order : false}
                >
                    <TableSortLabel
                        active={orderBy === headCell.id}
                        direction={orderBy === headCell.id ? order : 'asc'}
                        onClick={createSortHandler(headCell.id)}
                    >
                        {headCell.label}
                        {orderBy === headCell.id ? (
                            <Box component="span" sx={visuallyHidden}>
                                {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                            </Box>
                        ) : null}
                    </TableSortLabel>
                </TableCell>
            ))}
        </TableRow>

    );
}

EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
    const { numSelected } = props;

    return (
        <Toolbar
            sx={{
                pl: { sm: 2 },
                pr: { xs: 1, sm: 1 },
                ...(numSelected > 0 && {
                    bgcolor: (theme) =>
                        alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
                }),
            }}
        >
            {numSelected > 0 ? (
                <Typography
                    sx={{ flex: '1 1 100%' }}
                    color="inherit"
                    variant="subtitle1"
                    component="div"
                >
                    {numSelected} selected
                </Typography>
            ) : (
                <Typography
                    sx={{ flex: '1 1 100%' }}
                    variant="h6"
                    id="tableTitle"
                    component="div"
                >

                </Typography>
            )}

            {numSelected > 0 &&
                <Tooltip title="Delete">
                    <IconButton>
                        <DeleteIcon />
                    </IconButton>
                </Tooltip>
            }
        </Toolbar>
    );
};

EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
};

export default function JobTable() {
    const [order, setOrder] = React.useState('desc');
    const [orderBy, setOrderBy] = React.useState('start_date');
    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [dense, setDense] = React.useState(false);
    const [rowsPerPage, setRowsPerPage] = React.useState(50);
    const [rows, setRows] = React.useState([]);
    const [showFilter, setShowFilter] = React.useState(false);

    const [nameFilter, setNameFilter] = React.useState('');
    const [statusFilter, setStatusFilter] = React.useState('');
    const [idFilter, setIdFilter] = React.useState('');
    const [startDateFilter, setStarDateFilter] = React.useState('');
    const [endDateFilter, setEndDateFilter] = React.useState('');
    const [durationFilter, setDurationFilter] = React.useState('');
    const [machineFilter, setMachineFilter] = React.useState('');

    const navigate = useNavigate();

    const handleNameFilterChange = event => {
        setNameFilter(event.target.value);
    };
    const handleStatusFilterChange = event => {
        setStatusFilter(event.target.value);
    };
    const handleIdFilterChange = event => {
        setIdFilter(event.target.value);
    };
    const handleStarDateFilterChange = event => {
        setStarDateFilter(event.target.value);
    };
    const handleEndDateFilterChange = event => {
        setEndDateFilter(event.target.value);
    };
    const handleDurationFilterChange = event => {
        setDurationFilter(event.target.value);
    };
    const handleMachineFilterChange = event => {
        setMachineFilter(event.target.value);
    };

    const handleShowFilterChange = event => {
        if (showFilter) {
            setShowFilter(false);
        }
        else {
            setShowFilter(true);
        }

    };



    useEffect(() => {
        navigate('/jobs')
        let params = [['name', nameFilter], ['status', statusFilter], ['guid', idFilter], ['start_date', startDateFilter], ['end_date', endDateFilter], ['duration', durationFilter], ['machine', machineFilter]]
        let queryParams = "";
        params.forEach((param) => {
            if (param[1].length > 1) {
                queryParams = queryParams + param[0] + "=" + param[1] + "&"
            }
        })

        queryParams = queryParams.slice(0, -1)
        let query = "api/getjobs?" + queryParams
        fetch(query)
            .then(res => res.json())
            .then(res => {
                setRows(res.results); 
            })
            .catch(err => console.log(err));
    }, [nameFilter, statusFilter, idFilter, startDateFilter, endDateFilter, durationFilter, machineFilter]);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = rows.map((n) => n.guid);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, guid) => {
        const selectedIndex = selected.indexOf(guid);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, guid);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        setSelected(newSelected);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleChangeDense = (event) => {
        setDense(event.target.checked);
    };

    const isSelected = (guid) => selected.indexOf(guid) !== -1;

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    return (
        <Box sx={{ width: '100%' }}>
            <Paper sx={{ width: '100%', mb: 2 }}>
                <EnhancedTableToolbar numSelected={selected.length} />

                <TableContainer>

                    <Table
                        sx={{ minWidth: 750 }}
                        aria-labelledby="tableTitle"
                        size={dense ? 'small' : 'medium'}
                    > <TableHead>

                            <TableRow>
                                <Tooltip onClick={handleShowFilterChange} title="Filter list">
                                    <IconButton>
                                        <FilterListIcon />
                                    </IconButton>
                                </Tooltip>
                                {showFilter &&
                                    <>
                                        <TableCell><TextField onChange={handleNameFilterChange} id="nameFilter" label="Name" variant="standard" /></TableCell>
                                        <TableCell>
                                            <FormControl sx={{ m: 1, minWidth: 120 }} >
                                                <InputLabel id="statusFilter-label">Status</InputLabel>
                                                <Select
                                                    labelId="statusFilter-label"
                                                    id="statusFilter"
                                                    value={statusFilter}
                                                    onChange={handleStatusFilterChange}
                                                    label="Status"
                                                    variant="standard"
                                                >
                                                    <MenuItem value={''}>All</MenuItem>
                                                    <MenuItem value={'Succesfull'}>Succesfull</MenuItem>
                                                    <MenuItem value={'Failed'}>Failed</MenuItem>
                                                    <MenuItem value={'Cancelled'}>Cancelled</MenuItem>
                                                    <MenuItem value={'In progress'}>In progress</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </TableCell>
                                        <TableCell><TextField onChange={handleIdFilterChange} id="standard-basic" label="ID" variant="standard" /></TableCell>
                                        <TableCell><TextField onChange={handleMachineFilterChange} id="standard-basic" label="Machine" variant="standard" /></TableCell>
                                        <TableCell><TextField onChange={handleStarDateFilterChange} id="standard-basic" label="Start Date" variant="standard" /></TableCell>
                                        <TableCell><TextField onChange={handleEndDateFilterChange} id="standard-basic" label="End Date" variant="standard" /></TableCell>
                                        <TableCell><TextField onChange={handleDurationFilterChange} id="standard-basic" label="Duration" variant="standard" /></TableCell>
                                        <TableCell><TextField label="Exception" variant="standard" disabled /></TableCell></>
                                }
                            </TableRow>



                            <EnhancedTableHead
                                numSelected={selected.length}
                                order={order}
                                orderBy={orderBy}
                                onSelectAllClick={handleSelectAllClick}
                                onRequestSort={handleRequestSort}
                                rowCount={rows.length}
                            />
                        </TableHead>
                        <TableBody>

                            {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}
                            {stableSort(rows, getComparator(order, orderBy))
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row, index) => {
                                    const isItemSelected = isSelected(row.guid);
                                    const labelId = `enhanced-table-checkbox-${index}`;

                                    return (
                                        <TableRow
                                            hover
                                            // onClick={(event) => handleClick(event, row.guid)}
                                            role="checkbox"
                                            aria-checked={isItemSelected}
                                            tabIndex={-1}
                                            key={row.guid}
                                            selected={isItemSelected}
                                        >
                                            <TableCell padding="checkbox">
                                                <Checkbox
                                                    onClick={(event) => handleClick(event, row.guid)}
                                                    color="primary"
                                                    checked={isItemSelected}
                                                    inputProps={{
                                                        'aria-labelledby': labelId,
                                                    }}
                                                />
                                            </TableCell>
                                            <TableCell
                                                component="th"
                                                id={labelId}
                                                scope="row"
                                                padding="none"
                                            >
                                                {row.name}
                                            </TableCell>
                                            <TableCell>
                                                {row.status == "Succesfull" && <ColorChips label={row.status} color='success'></ColorChips>}
                                                {row.status == "Failed" && <ColorChips label={row.status} color='error'></ColorChips>}
                                                {row.status == "In progress" && <ColorChips label={row.status} color='primary'></ColorChips>}
                                                {row.status == "Pending" && <ColorChips label={row.status} color='secondary'></ColorChips>}
                                                {row.status == "Cancelled" && <ColorChips label={row.status} color='warning'></ColorChips>}
                                            </TableCell>
                                            <TableCell>{row.guid}</TableCell>
                                            <TableCell>{row.machine}</TableCell>
                                            <TableCell>{row.start_date}</TableCell>
                                            <TableCell>{row.end_date}</TableCell>
                                            <TableCell>{row.duration}</TableCell>
                                            <TableCell>
                                                {row.exception.length > 0 &&
                                                    <PopoverComponent content={row.exception} variant='outlined' title='Show' color='error'></PopoverComponent>
                                                }
                                            </TableCell>
                                            <TableCell>
                                                {row.status == "In progress" &&
                                                    <div><KillJobButton guid={row.guid}></KillJobButton> <Button color="warning">Stop</Button></div>
                                                }
                                            </TableCell>
                                            <TableCell>
                                                <JobActionsMenu row={row}></JobActionsMenu>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            {emptyRows > 0 && (
                                <TableRow
                                    style={{
                                        height: (dense ? 33 : 53) * emptyRows,
                                    }}
                                >
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25, 50]}
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
            <FormControlLabel
                control={<Switch checked={dense} onChange={handleChangeDense} />}
                label="Dense padding"
            />
        </Box>
    );
}



