import React, {  } from 'react';

export default function Home({ firstName}) {

  
      
    

 
       
    return (
      <div>
            <h1>Welcome back {firstName}</h1>


            <a href='/RobotForceInstaller.exe' download>Download latest release</a>
        </div>
    );
  }


