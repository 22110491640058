import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";
import DatabaseView from '../components/ManagementPageComponents/DatabaseView';

export default function AdminManagement({ initialTab }) {


const [tabValue, setTabValue] = useState(initialTab);



const handleTabChange = (event, newValue) => {
    setTabValue(newValue)
};




        return (
            <div>
                <Box sx={{ width: '100%' }}>
                    
                    <Tabs
                        value={tabValue}
                        onChange={handleTabChange}
                        textColor="secondary"
                        indicatorColor="secondary"
                        aria-label="robots tabs"
                    >
                        
                        <Tab tag={Link} to="/management/users" value="Users" label="Users"/>
                        <Tab tag={Link} to="/management/database"  value="Database View" label="Database View" />
                    </Tabs>
                </Box>

                
                {
                    tabValue == "Users" &&
                    <>
                        

                        
                    </>

                }

                {
                    tabValue == "Database View" &&
                    <>
                        <DatabaseView></DatabaseView>
                        
                    </>
                }


            </div>
        );
    }

