import * as React from 'react';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Popper from '@mui/material/Popper';
import PopupState, { bindToggle, bindPopper, bindTrigger, bindPopover  } from 'material-ui-popup-state';
import Paper from '@mui/material/Paper';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Popover from '@mui/material/Popover';

export default function PopoverComponent(props) {

  

    return (
        <PopupState variant="popper" popupId="demo-popup-popper">
            {(popupState) => (
                <div>
                        <Button variant={props.variant} color={props.color} {...bindTrigger(popupState)}>
                        {props.title}
                    </Button>
                        <Popover {...bindPopover(popupState)}
                            anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                        }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'center',
                            }}>
                        
                                    <Typography sx={{ p: 2 }}>{props.content}</Typography>
                        
                        </Popover>
                </div>
            )}
            </PopupState>
    );
}
