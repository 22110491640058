import '../custom.css';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import logo from '../components/LogoRobotForce.svg';


export default function Login ({ setToken }) {
    const [username, setUserName] = useState();
    const [password, setPassword] = useState();
    const [loginErrorMessage, setLoginErrorMessage] = useState();
    const [loginStatusCode, setLoginStatusCode] = useState();

    const handleSubmit = async e => {
        e.preventDefault();
        loginUser({
            username,
            password
        });
        
    }
    function loginUser(credentials) {
        fetch('api/login', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(credentials)
        })
            .then(data => { setLoginStatusCode(data.status); return data.json() })
            .then(data => {
                setToken(data);
                if (loginStatusCode != 200) {
                    let error = JSON.stringify(data.errors)
                    setLoginErrorMessage(error)
                }
                
                
            })
            

    }

    return (

        <Box mt={2}
                    component="form"
                    sx={{
                        '& .MuiTextField-root': { m: 1, width: '25ch' },
                    }}
                    noValidate
                    autoComplete="off">
            <div className="login-wrapper">
                <Card sx={{ maxWidth: 700, textAlign:'center' }}>
                    <CardMedia
                        component="img"
                        alt="green iguana"
                        height="140"
                        className="loginCard"
                        image={logo}
                    />
                    <CardContent>
                        <Box sx={{ textAlign: '-webkit-center', width: '100%' }}>
                            {loginStatusCode && loginStatusCode != 200  &&
                                <Typography color="error" variant="body2" gutterBottom>
                                    {loginStatusCode + ": " + loginErrorMessage}
                                </Typography>
                            }
                        <Typography variant="h5" gutterBottom>
                            Log in to Command Center
                            </Typography>
                            </Box>
                    </CardContent>
                    <CardActions sx={{ display: 'inline-grid' }}>
                        
                            <TextField
                                required
                                value={username}
                                id="username-input"
                                label="Username"
                                onChange={e => setUserName(e.target.value)}
                            />
                            

                                <TextField
                                    required
                                    value={password}
                                    id="password-input"
                                    label="Password"
                                    type="password"
                                    autoComplete="current-password"
                                    onChange={e => setPassword(e.target.value)}
                                />
                            
                            
                        <Button disabled={username && password ? false : true}  size="large" type="submit" onClick={handleSubmit} variant="contained" color='neutral' >
                                    Login
                                </Button>
                            
                       
                    </CardActions>
                </Card>
                        
                    </div>
                </Box>

         


        
    )
}

Login.propTypes = {
    setToken: PropTypes.func.isRequired
};