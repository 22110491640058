import React, {  useState, useEffect } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Route, Routes } from 'react-router';
import { Layout } from './components/Layout';
import Home  from './Pages/Home';
import  Jobs  from './Pages/Jobs';
import  Robots  from './Pages/Robots';
import Login from './Pages/Login';
import useToken from './components/items/UseToken'
import './custom.css'
import './robot.css'
import AdminManagement from './Pages/AdminManagement';
import Data from './Pages/Data';


function App() {
    const theme = createTheme({
        palette: {

            neutral: {
                main: '#64748B',
                contrastText: '#fff',
            },
        },
    });
    const [loggedUser, setLoggedUser] = useState(null);
    const [isAuth, setIsAuth] = useState();
    const { token, setToken } = useToken();

    
    const logOut = () => {

        fetch('api/logout', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ token: token })
        })
            
            .then(data => {

                localStorage.removeItem('token');
                setToken("dummy")

            })
      

    }

    useEffect(() => {
        const tokenString = localStorage.getItem('token');
        const userToken = JSON.parse(tokenString);
        

        if (userToken) {
            fetch('api/authsession', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ token: userToken?.token })
            })
                .then(data => data.json())
                .then(data => {

                    setLoggedUser(data)
                    setIsAuth(true)

                })

                
        }
    }, [token]);
    


    
    return (
        <div>
            <ThemeProvider theme={theme}>
                {
                    !token && <Login setToken={setToken} />
                }
                {(token && isAuth && loggedUser.firstname) &&
                    <Layout logOut={logOut}>
                    <Routes>
                        <Route exact path='/' element={<Home firstName={loggedUser.firstname}/>} />
                        <Route exact path='/jobs' element={<Jobs initialTab="Jobs" />} />
                        <Route exact path='/robots' element={<Robots initialTab="Processes" />} />
                        <Route exact path='/management' element={<AdminManagement initialTab="Users" />} />
                        <Route exact path='/data' element={<Data initialTab="Queues" />} />
                        <Route path='/robots/processes' element={<Robots initialTab="Processes" />} />
                        <Route path='/robots/machines' element={<Robots initialTab="Machines" />} />
                        <Route path='/jobs/scheduler' element={<Jobs initialTab="Scheduler" />} />
                        <Route path='/management/users' element={<AdminManagement initialTab="Users" />} />
                        <Route path='/management/database' element={<AdminManagement initialTab="Database View" />} />
                        <Route path='/data/queues' element={<Data initialTab="Queues" />} />
                        <Route path='/data/storage' element={<Data initialTab="Storage" />} />
                        </Routes>
                    </Layout>
                }
            </ThemeProvider>
        </div>
    );
}
export default App;