import * as React from 'react';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';

export default function ColorChips(props) {
    return (
        <Stack spacing={1} alignItems="center">
            <Chip variant="outlined" label={props.label} color={props.color} />
           
        </Stack>
    );
}
