import * as React from 'react';
import Button from '@mui/material/Button';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useState } from 'react';


export default function JobActionsMenu(props) {

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleRestartJob = () => {
        
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ machine: props.row.machine_id,  process_id: props.row.process_id })
            };
        fetch('api/runjob', requestOptions);
        handleClose()
    }
    const handleDetailsDialogOpen = () => {

    }




    return (
        <>
            <Button
                id="job-button"
                aria-controls={open ? 'job-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
                color='neutral'
            >
                <MoreVertIcon />
            </Button>

            <Menu
            id="job-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
                'aria-labelledby': 'job-button',
            }}
        >
                <MenuItem dense={true} ><Button  onClick={handleRestartJob} color="warning">Restart Job</Button></MenuItem>
                <MenuItem dense={true} ><Button  onClick={handleDetailsDialogOpen} color="info">View Details</Button></MenuItem>
        </Menu>

            

            
        </>
    );
}
