import * as React from 'react';
import TextField from '@mui/material/TextField';
import DropDownOption from './items/UserOption';
import { useEffect } from 'react';

export default function RobotExecuteForm({ handleClose }) {
    const [machineName, setMachineName] = React.useState('Choose');
    const [machineList, setMachineList] = React.useState('');
    const [processId, setProcessId] = React.useState('Choose');
    const [processList, setProcessList] = React.useState('');

    useEffect(() => {

        fetch('api/getmachines')
            .then((res) => {
                let data = res.json()
                    .then((res) => {
                        let results = res.results;
                        setMachineList(results);

                    }
                    )
            });

        fetch('api/getrobots?is_active=1')
            .then((res) => {
                let data = res.json()
                    .then((res) => {
                        let results = res.results;
                        setProcessList(results);

                    }
                    )
            });
        

    },[])

    const handleSubmit = (event) => {
        console.log(machineName);


        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ machine: machineName, text:"", process_id: processId })
        };
        fetch('api/runjob', requestOptions)
            .then(() => {

                handleClose();
            });
    };
    const handleMachineNameChange = event => {
        setMachineName(event.target.value);
    };

    const handleProcessIdChange = event => {
        setProcessId(event.target.value);
    };

    const renderMachineList = () => {
        const userList = [];
        for (let i = 0; i < machineList.length; i++) {

            let username = machineList[i].name;
            let machinekey = machineList[i].guid;
            let key = machineList[i].user + i.toString();

            userList.push(<DropDownOption value={machinekey} name={username} key={key}></DropDownOption>);
        }

        return userList;
    }

    const renderProcessList = () => {
        const robotList = [];
        for (let i = 0; i < processList.length; i++) {

            let robot = processList[i].name;
            let id = processList[i].id;
            let version_date = processList[i].version_date;
            let version = processList[i].version;
            let key = processList[i].robot + i.toString();

            robotList.push(<DropDownOption value={id} name={robot + " ver." + version + " (" + version_date + ")"} key={key}></DropDownOption>);
        }

        return robotList;
    }





    return (
        machineList.length > 0 && <>



            <div className="form-group mb-3">
                <div className="form-group mb-3">
                    <label>
                        Choose the machine:
                        <select className="form-control" name="machine" value={machineName} onChange={handleMachineNameChange}>
                            <option value="Choose" disabled hidden>Choose</option>
                            {renderMachineList()}


                        </select>
                    </label>
                </div>
                <div className="form-group mb-3">
                    <label>
                        Choose the process:
                        <select className="form-control" name="process" value={processId} onChange={handleProcessIdChange}>
                            <option value="Choose" disabled hidden>Choose</option>
                            {renderProcessList()}


                        </select>
                    </label>
                </div>
            </div>
            <button className="btn btn-primary" type="submit" onClick={handleSubmit}>Start Job</button>

        </>
    );
}
