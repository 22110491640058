import React, { useState } from 'react';
import JobTable from '../components/JobsPageComponents/JobTable'
import Box from '@mui/material/Box';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import RobotExecuteForm  from '../components/RobotExecuteForm';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { useEffect } from 'react';


export default function Jobs ({ initialTab }) {

    const [isDialogOpen, setIsDialogOpen] = useState();
    const [tabValue, setTabValue] = useState(initialTab);

   

    const handleClickOpen = () => {
        setIsDialogOpen(true)
    };

   const handleClose = () => {
       setIsDialogOpen(false)
    };

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue)
    };
     


 
        
    return (
        <div>
            <Box sx={{ width: '100%' }}>
                <Tabs
                    value={tabValue}
                    onChange={handleTabChange}
                    textColor="secondary"
                    indicatorColor="secondary"
                    aria-label="robots tabs"
                >
                    <Tab value="Jobs" label="Jobs" />
                    <Tab value="Scheduler" label="Scheduler" />
                </Tabs>
            </Box>
            { // jobs tab
                tabValue == "Jobs" && 
                <>
            <Box  sx={{ '& > :not(style)': { m: 1 } }}>
                <Fab onClick={handleClickOpen} title="Add Job" className="floatRight" color="primary" aria-label="add">
                    <AddIcon />
                </Fab>
               
            </Box>

            
            
                    <JobTable></JobTable>
                    <Dialog open={isDialogOpen} onClose={handleClose}>
                        <DialogTitle>Start Job</DialogTitle>
                        <DialogContent>
                            <RobotExecuteForm handleClose={handleClose}></RobotExecuteForm>
                        </DialogContent>

                    </Dialog>
                </>
                // end of jobs tab
            }
            
        
      </div>
    )
}


