import * as React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import GenericDataTable from '../Common/GenericDataTable'

export default function DatabaseView() {
    const [dataTableName, setdataTableName] = React.useState('');

    const handleChange = (event) => {
        setdataTableName(event.target.value);
    };


    return (
        <>
        <Box sx={{ minWidth: 120 }}>
            <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Table Select</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={dataTableName}
                    label="Table Select"
                    onChange={handleChange}
                >
                    <MenuItem value={"jobs"}>Jobs</MenuItem>
                    <MenuItem value={"processes"}>Processes</MenuItem>
                        <MenuItem value={"machines"}>Machines</MenuItem>
                        <MenuItem value={"login"}>Login</MenuItem>
                        <MenuItem value={"logintoken"}>Login tokens</MenuItem>
                        <MenuItem value={"queues"}>Queues</MenuItem>
                        <MenuItem value={"queue_items"}>Queue items</MenuItem>

                </Select>
            </FormControl>
        </Box>
            <GenericDataTable pageUrl="" fetchUrl={"api/getdatabase?table_name=" + dataTableName} > </GenericDataTable>
            </>
    );
}
